<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" viewBox="0 0 149 41" version="1.1">
    <title>CAPTURE</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="app.cardess-02-1" transform="translate(-640.000000, -120.000000)">
            <g id="CAPTURE" transform="translate(640.000000, 120.000000)">
                <g id="Layer_1-2-2" transform="translate(45.000000, 27.000000)" fill-rule="nonzero">
                    <path d="M0.065,4.14 L0.065,4.11 C0.0515030923,3.074184 0.455989783,2.07663194 1.18707176,1.34272725 C1.91815374,0.608822566 2.91413968,0.200494761 3.95,0.21 C5.09441765,0.15989472 6.19911445,0.634914346 6.95,1.5 L5.88,2.64 C5.41103494,2.05878132 4.70678291,1.71765924 3.96,1.71 C3.3567274,1.73319138 2.78808248,1.99784649 2.38188408,2.44447668 C1.97568568,2.89110686 1.7660145,3.48223914 1.8,4.085 L1.8,4.085 C1.75604683,4.70082056 1.96650629,5.30773106 2.38227591,5.76413271 C2.79804553,6.22053436 3.38276032,6.48650833 4,6.5 C4.7474031,6.48635013 5.45261636,6.15105494 5.935,5.58 L6.965,6.58 C5.91557667,7.83577785 4.19510828,8.30514242 2.65335428,7.75626908 C1.11160028,7.20739573 0.0747835562,5.75642169 0.055,4.12 L0.065,4.14 Z" id="Path" fill="#231F20"/>
                    <path d="M7.34,5.68 L7.34,5.68 C7.34,4.065 8.6,3.275 10.42,3.275 C11.0989271,3.2710893 11.7741138,3.37574324 12.42,3.585 L12.42,3.4 C12.42,2.34 11.77,1.775 10.565,1.775 C9.82955166,1.77747786 9.10319879,1.93775291 8.435,2.245 L8,0.875 C8.87974638,0.460792535 9.84275527,0.253822597 10.815,0.27 C11.7167495,0.20084579 12.6079959,0.50033347 13.285,1.1 C13.8563164,1.72647903 14.1470866,2.559057 14.09,3.405 L14.09,7.83 L12.425,7.83 L12.425,6.895 C11.8311964,7.61872485 10.9357698,8.02674401 10,8 C8.585,8 7.34,7.18 7.34,5.68 Z M12.455,5.145 L12.455,4.645 C11.9125797,4.44463513 11.3382192,4.34467269 10.76,4.35 C9.66,4.35 9.01,4.815 9.01,5.59 L9.01,5.62 C9.01,6.34 9.645,6.75 10.465,6.75 C11.595,6.75 12.465,6.1 12.465,5.155 L12.455,5.145 Z" id="Shape" fill="#231F20"/>
                    <path d="M15.425,0.365 L17.13,0.365 L17.13,2.05 C17.6,0.93 18.46,0.17 19.775,0.225 L19.775,2.035 L19.675,2.035 C18.175,2.035 17.13,3.035 17.13,4.99 L17.13,7.83 L15.425,7.83 L15.425,0.365 Z" id="Path" fill="#231F20"/>
                    <path d="M28.145,4.125 L28.145,4.125 C28.145,1.99 29.645,0.235 31.79,0.235 C34.165,0.235 35.365,2.1 35.365,4.235 C35.365,4.39 35.365,4.545 35.335,4.735 L29.835,4.735 C29.9502442,5.83120738 30.8988117,6.64833367 32,6.6 C32.7626605,6.59525566 33.4882933,6.27053497 34,5.705 L35,6.6 C34.2688731,7.5036525 33.1622093,8.02009561 32,8 C30.962293,8.04013261 29.9551586,7.6440871 29.2227427,6.90787144 C28.4903269,6.17165577 28.0994982,5.16248545 28.145,4.125 Z M33.67,3.575 C33.555,2.47 32.91,1.61 31.775,1.61 C30.73,1.61 29.995,2.415 29.84,3.575 L33.67,3.575 Z" id="Shape" fill="#D02728"/>
                    <path d="M35.775,6.855 L36.535,5.7 C37.2350211,6.25523712 38.09239,6.57543814 38.985,6.615 C39.72,6.615 40.14,6.305 40.14,5.81 L40.14,5.78 C40.14,5.205 39.35,5.005 38.475,4.735 C37.37,4.42 36.14,3.97 36.14,2.545 L36.14,2.5 C36.14,1.085 37.315,0.22 38.8,0.22 C39.7768076,0.239058839 40.7283553,0.533709295 41.545,1.07 L40.865,2.285 C40.2357738,1.86805885 39.5082835,1.62326353 38.755,1.575 C38.09,1.575 37.71,1.885 37.71,2.31 L37.71,2.34 C37.71,2.89 38.515,3.115 39.39,3.4 C40.48,3.74 41.71,4.235 41.71,5.575 L41.71,5.605 C41.71,7.175 40.495,7.95 38.94,7.95 C37.7933681,7.94252833 36.6810977,7.55771439 35.775,6.855 Z" id="Path" fill="#231F20"/>
                    <path d="M42,6.855 L42.765,5.7 C43.4628021,6.2557603 44.3187741,6.5760934 45.21,6.615 C45.945,6.615 46.365,6.305 46.365,5.81 L46.365,5.78 C46.365,5.205 45.575,5.005 44.7,4.735 C43.6,4.42 42.37,3.97 42.37,2.545 L42.37,2.5 C42.37,1.085 43.54,0.22 45.025,0.22 C46.0005831,0.236992357 46.9510877,0.531856923 47.765,1.07 L47.085,2.285 C46.4607503,1.88058141 45.7424642,1.6445977 45,1.6 C44.335,1.6 43.95,1.91 43.95,2.335 L43.95,2.335 C43.95,2.89 44.755,3.115 45.635,3.395 C46.72,3.735 47.95,4.23 47.95,5.575 L47.95,5.575 C47.95,7.145 46.735,7.92002697 45.18,7.92002697 C44.0318341,7.92368594 42.9143604,7.54943768 42,6.855 Z" id="Path" fill="#231F20"/>
                    <path d="M20.6,0.21 L23.45,0.21 C25.85,0.21 27.51,1.89 27.51,4.085 L27.51,4.085 C27.51,6.275 25.85,7.98 23.45,7.98 L20.6,7.98 L20.6,0.21 Z M22.23,1.75 L22.23,6.605 L23.45,6.605 C25.055,6.605 25.89,5.655 25.89,4.105 L25.89,4.105 C25.89,2.58 25.055,1.75 23.45,1.75 L22.23,1.75 Z" id="Shape" fill="#D02728"/>
                    <path d="M48.315,0.685 L48.315,0.685 C48.315,0.30944637 48.6194464,0.005 48.995,0.005 C49.3705536,0.005 49.675,0.30944637 49.675,0.685 L49.675,0.685 C49.675,1.06055363 49.3705536,1.365 48.995,1.365 C48.6194464,1.365 48.315,1.06055363 48.315,0.685 L48.315,0.685 Z M49.595,0.685 L49.595,0.685 C49.595,0.35362915 49.3263708,0.085 48.995,0.085 C48.6636292,0.085 48.395,0.35362915 48.395,0.685 L48.395,0.685 C48.3936538,0.845864899 48.4569623,1.00053243 48.5707149,1.11428508 C48.6844676,1.22803772 48.8391351,1.29134622 49,1.29 L49,1.29 C49.16,1.29000556 49.313375,1.22610556 49.4260417,1.1125 C49.5387083,0.998894445 49.6013333,0.844994444 49.6,0.685 L49.595,0.685 Z M48.74,0.335 L49.045,0.335 C49.185,0.335 49.3,0.41 49.3,0.545 C49.2994489,0.645121984 49.2282907,0.730930374 49.13,0.75 L49.325,1.01 L49.185,1.01 L49.015,0.765 L48.85,0.765 L48.85,1 L48.74,1 L48.74,0.335 Z M49.035,0.675 C49.13,0.675 49.185,0.625 49.185,0.55 C49.185,0.475 49.125,0.43 49.035,0.43 L48.85,0.43 L48.85,0.675 L49.035,0.675 Z" id="Shape" fill="#D02728"/>
                </g>
                <path d="M45.92,12.54 L45.92,12.5 C45.92,8.32 49.245,5.205 53.505,5.205 C56.645,5.205 58.765,6.705 59.965,8.84 L56.03,11.1 C55.5,10.1 54.75,9.4 53.445,9.4 C51.835,9.4 50.77,10.78 50.77,12.46 L50.77,12.5 C50.77,14.32 51.875,15.6 53.445,15.6 C54.79,15.6 55.54,14.88 56.135,13.84 L60.07,16 C58.865,18.08 56.87,19.7801021 53.32,19.7801021 C49.365,19.8 45.92,16.88 45.92,12.54 Z" id="Path" fill="#231F20" fill-rule="nonzero"/>
                <path d="M65.9,5.4 L70.63,5.4 L76.63,19.5 L71.465,19.5 L70.71,17.62 L65.71,17.62 L65,19.5 L59.9,19.5 L65.9,5.4 Z M69.53,14.3 L68.225,10.9 L66.92,14.3 L69.53,14.3 Z" id="Shape" fill="#231F20" fill-rule="nonzero"/>
                <path d="M77.5,5.5 L83.925,5.5 C87.715,5.5 90.305,7.16 90.305,10.5 L90.305,10.5 C90.305,13.88 87.675,15.68 83.805,15.68 L82.255,15.68 L82.255,19.5 L77.5,19.5 L77.5,5.5 Z M83.55,12.36 C84.775,12.36 85.59,11.82 85.59,10.82 L85.59,10.78 C85.59,9.78 84.835,9.24 83.59,9.24 L82.285,9.24 L82.285,12.36 L83.55,12.36 Z" id="Shape" fill="#231F20" fill-rule="nonzero"/>
                <polygon id="Path" fill="#231F20" fill-rule="nonzero" points="95.26 9.46 91.06 9.46 91.06 5.5 104.23 5.5 104.23 9.46 100.03 9.46 100.03 19.5 95.26 19.5"/>
                <path d="M105.25,13.22 L105.25,5.5 L110.1,5.5 L110.1,13.16 C110.1,14.9 111.02,15.66 112.3,15.66 C113.58,15.66 114.5,14.96 114.5,13.26 L114.5,5.5 L119.35,5.5 L119.35,13.12 C119.35,17.9 116.515,19.78 112.255,19.78 C107.995,19.78 105.25,17.86 105.25,13.22 Z" id="Path" fill="#231F20" fill-rule="nonzero"/>
                <path d="M121.5,5.5 L128.47,5.5 C131,5.5 132.61,6.12 133.61,7.1 C134.498538,7.96993425 134.973345,9.17787215 134.915,10.42 L134.915,10.46 C134.959508,12.3336706 133.838063,14.0387451 132.1,14.74 L135.4,19.5 L129.955,19.5 L127.265,15.5 L126.265,15.5 L126.265,19.5 L121.5,19.5 L121.5,5.5 Z M128.33,12.16 C129.47,12.16 130.165,11.66 130.165,10.8 L130.165,10.76 C130.165,9.86 129.45,9.42 128.35,9.42 L126.27,9.42 L126.27,12.16 L128.33,12.16 Z" id="Shape" fill="#231F20" fill-rule="nonzero"/>
                <polygon id="Path" fill="#231F20" fill-rule="nonzero" points="136.565 5.5 148.735 5.5 148.735 9.34 141.235 9.34 141.235 10.76 148.205 10.76 148.205 14.14 141.255 14.14 141.255 15.64 148.835 15.64 148.835 19.5 136.565 19.5"/>
                <polygon id="Path" fill="#D02727" fill-rule="nonzero" points="17.25 19.995 17.25 40 34.5 30 34.5 9.995"/>
                <polygon id="Path" fill="#9F1D21" fill-rule="nonzero" points="0 9.995 0 30 17.25 40 17.25 19.995"/>
                <polygon id="Path" fill="#ED3726" fill-rule="nonzero" points="17.25 0 0 10 17.25 20.005 34.5 10"/>
                <path d="M26.42,11.75 L25.3,7.965 L17.45,7.965 L16.33,11.75 L11.28,11.75 L11.28,11.605 C11.1715111,10.9728728 10.6054925,10.5231085 9.96519924,10.5602455 C9.32490595,10.5973825 8.81468052,11.109569 8.78,11.75 L7.5,11.75 L7.5,28.15 L27.68,28.15 L27.68,11.75 L26.42,11.75 Z M21.555,22.5 L17.25,25 L12.945,22.5 L12.945,17.5 L12.945,17.5 L17.25,15 L21.555,17.5 L21.555,17.5 L21.555,22.5 Z M25.79,15.535 C25.4420606,15.535 25.16,15.2529394 25.16,14.905 C25.16,14.5570606 25.4420606,14.275 25.79,14.275 C26.1379394,14.275 26.42,14.5570606 26.42,14.905 C26.4213454,15.0724952 26.3554027,15.2335211 26.2369619,15.3519619 C26.1185211,15.4704027 25.9574952,15.5363454 25.79,15.535 L25.79,15.535 Z" id="Shape" fill="#FFFFFF"/>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  mixins: [IconBase],
  props: {
    width: {
      type: [String],
      default: '149px'
    },
    height: {
      type: [String],
      default: '41px'
    }
  }
};
</script>
