import Vue from 'vue';
import VueRouter from 'vue-router';
import CardessSell from '@/components/CardessSell';
import CardessCapture from '@/components/CardessCapture';
import CardessCheck from '@/components/CardessCheck';
import CardessAppraisal from '@/components/CardessAppraisal';

Vue.use(VueRouter);

const routes = [{
  name: 'CardessSell',
  path: '/cardess-sell',
  component: CardessSell
}, {
  name: 'CardessCapture',
  path: '/cardess-capture',
  component: CardessCapture
}, {
  name: 'CardessCheck',
  path: '/cardess-check',
  component: CardessCheck
}, {
  name: 'CardessAppraisal',
  path: '/cardess-appraisal',
  component: CardessAppraisal
}, {
  path: '*',
  redirect: { name: 'CardessAppraisal' }
}];

const router = new VueRouter({
  routes
});

export default router;
