<template>
  <div>
    <div class="content--logo"><icon-check /></div>
    <div class="content--header">
      <div class="content--headline">{{$t('cardessAppraisal.headline')}}</div>

      <!--
      <div class="content--manual">
        <icon-download />
        <span> {{$t('manual')}}</span>
      </div>
      -->
    </div>
    <div class="content--container">
      <!--
      <div>
        <div class="content--subheadline">
          {{$t('cardessAppraisal.about.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessAppraisal.about.description')}}
        </div>
      </div>
      -->
      <!--
      <div>
        <div class="content--subheadline">
          {{$t('cardessAppraisal.howItWorks.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessAppraisal.howItWorks.description')}}
        </div>
      </div>
      -->
      <!--
      <div>
        <div class="content--subheadline">
          {{$t('cardessAppraisal.technical.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessAppraisal.technical.description')}}
        </div>
      </div>
      -->
      <div>
        <itms-services-link :title="$t('cardessAppraisal.button.sellApp')" file-name="appraisal.plist" />
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck';
import ItmsServicesLink from '@/components/ItmsServicesLink';

export default {
  name: 'CardessAppraisal',
  components: {
    ItmsServicesLink,
    IconCheck
  }
}
</script>
