// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router/index';
import { createI18n } from './i18n';
import FlagIcon from 'vue-flag-icon';

const i18n = createI18n();

Vue.config.productionTip = false;
Vue.i18n = i18n;
Vue.prototype.i18n = i18n;

Vue.use(FlagIcon);

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#fg-app-center');
