<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" viewBox="0 0 147 24" version="1.1">
    <title>cardess main logo</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="app.cardess-02" transform="translate(-200.000000, -18.000000)" fill-rule="nonzero">
            <g id="cardess-main-logo" transform="translate(200.000000, 18.000000)">
                <path d="M0,12.4759664 L0,12.3912605 C0,6.10487395 4.8550274,0.822857143 11.5103014,0.822857143 C15.6524795,0.822857143 18.248137,2.37378151 20.3383562,4.63865546 L17.158726,8.03294118 C15.6101918,6.39731092 13.9790959,5.26588235 11.4780822,5.26588235 C7.7950274,5.26588235 5.07452055,8.45243697 5.07452055,12.3065546 L5.07452055,12.3912605 C5.06445205,16.3361345 7.7849589,19.4763025 11.6794521,19.4763025 C14.0656849,19.4763025 15.8236438,18.3852101 17.4144658,16.7495798 L20.4692466,19.7747899 C18.2924384,22.194958 15.7410822,23.9233613 11.4297534,23.9233613 C4.8550274,23.9173109 0,18.7563025 0,12.4759664 Z" id="Path" fill="#231F20"/>
                <path d="M21.5465753,17.0440336 L21.5465753,16.9613445 C21.5465753,12.1815126 25.2719178,9.83394958 30.6706438,9.83394958 C32.6886136,9.81355196 34.696201,10.1256137 36.6130685,10.7576471 L36.6130685,10.2110924 C36.6130685,7.06890756 34.6879726,5.39092437 31.1317808,5.39092437 C28.6629863,5.39092437 26.8204521,5.93546218 24.8107808,6.77445378 L23.4716712,2.79327731 C26.0739767,1.5631235 28.9230576,0.946341665 31.8003288,0.990252101 C35.1068219,0.990252101 37.5756164,1.86957983 39.1241507,3.46285714 C40.7572603,5.05613445 41.5103836,7.40369748 41.5103836,10.2957983 L41.5103836,23.4151261 L36.5727945,23.4151261 L36.5727945,20.6480672 C35.0665479,22.4510924 32.7648904,23.8749593 29.3758356,23.8749593 C25.2316438,23.8769748 21.5465753,21.4870588 21.5465753,17.0440336 Z M36.6976438,15.4507563 L36.6976438,13.9421849 C35.0910715,13.3450359 33.389047,13.0470295 31.6754795,13.0628571 C28.411274,13.0628571 26.4861781,14.4443697 26.4861781,16.7495798 L26.4861781,16.8342857 C26.4861781,18.9721008 28.3689863,20.1882353 30.7954932,20.1882353 C34.1462877,20.1882353 36.6996575,18.2601681 36.6996575,15.4507563 L36.6976438,15.4507563 Z" id="Shape" fill="#231F20"/>
                <path d="M45.483411,1.28470588 L50.5438356,1.28470588 L50.5438356,6.27226891 C51.9252329,2.96067227 54.4786027,0.697815126 58.3690685,0.865210084 L58.3690685,6.22991597 L58.0770822,6.22991597 C53.6469452,6.22991597 50.5438356,9.12201681 50.5438356,14.9909244 L50.5438356,23.4151261 L45.483411,23.4151261 L45.483411,1.28470588 Z" id="Path" fill="#231F20"/>
                <path d="M83.1536712,12.4336134 L83.1536712,12.3489076 C83.1536712,6.02016807 87.632137,0.822857143 93.9511233,0.822857143 C100.982959,0.822857143 104.541164,6.35495798 104.541164,12.7220168 C104.541164,13.1838655 104.498877,13.6457143 104.456589,14.1478992 L88.2161096,14.1478992 C88.7618219,17.7478992 91.3172055,19.7647059 94.581411,19.7647059 C97.0502055,19.7647059 98.8101781,18.8430252 100.56411,17.1226891 L103.536329,19.7647059 C101.444096,22.2796639 98.5564521,23.9132799 94.4948219,23.9132799 C88.093274,23.9173109 83.1536712,19.2665546 83.1536712,12.4336134 Z M99.519,10.7979832 C99.184726,7.5287395 97.2616438,4.97142857 93.9088356,4.97142857 C90.8137808,4.97142857 88.6369726,7.36134454 88.1758356,10.7979832 L99.519,10.7979832 Z" id="Shape" fill="#D02728"/>
                <path d="M105.74737,20.5230252 L108.008753,17.0944538 C110.437274,18.897479 112.98863,19.8171429 115.258068,19.8171429 C117.434877,19.8171429 118.681356,18.897479 118.681356,17.4292437 L118.681356,17.3445378 C118.681356,15.6262185 116.337411,15.0393277 113.741753,14.2406723 C110.477548,13.3109244 106.836781,11.9717647 106.836781,7.73848739 L106.836781,7.66386555 C106.836781,3.47092437 110.310411,0.913563207 114.704301,0.913563207 C117.46911,0.905546218 120.479589,1.86957983 122.835616,3.42857143 L120.821918,7.02655462 C118.687397,5.72773109 116.426014,4.93109244 114.579452,4.93109244 C112.610055,4.93109244 111.478356,5.85277311 111.478356,7.1112605 L111.478356,7.19394958 C111.478356,8.82957983 113.866603,9.49915966 116.460247,10.3381513 C119.682164,11.3465546 123.322932,12.8107563 123.322932,16.7919328 L123.322932,16.8766387 C123.322932,21.5294118 119.726466,23.8346218 115.121137,23.8346218 C111.718655,23.7892621 108.425254,22.625758 105.74737,20.5230252 Z" id="Path" fill="#231F20"/>
                <path d="M124.202918,20.5230252 L126.462288,17.0944538 C128.878726,18.897479 131.444178,19.8171429 133.711603,19.8171429 C135.886397,19.8171429 137.13489,18.897479 137.13489,17.4292437 L137.13489,17.3445378 C137.13489,15.6262185 134.788932,15.0393277 132.195288,14.2406723 C128.933096,13.3109244 125.292329,11.9717647 125.292329,7.73848739 L125.292329,7.66386555 C125.292329,3.47092437 128.763945,0.913563207 133.159849,0.913563207 C135.924658,0.905546218 138.945205,1.86957983 141.277068,3.42857143 L139.26337,7.03260504 C137.130863,5.73378151 134.869479,4.93714286 133.020904,4.93714286 C131.055534,4.93714286 129.923836,5.85882353 129.923836,7.11731092 L129.923836,7.2 C129.923836,8.83563025 132.308055,9.50521008 134.903712,10.3442017 C138.12563,11.352605 141.768411,12.8168067 141.768411,16.7979832 L141.768411,16.8826891 C141.768411,21.5354622 138.167918,23.8406723 133.564603,23.8406723 C130.165438,23.7916853 126.876387,22.6260927 124.202918,20.5230252 L124.202918,20.5230252 Z" id="Path" fill="#231F20"/>
                <path d="M60.8136986,0.822857143 L69.2510959,0.822857143 C76.3654932,0.822857143 81.2789178,5.80436975 81.2789178,12.3045378 L81.2789178,12.3710924 C81.2789178,18.8712605 76.3654932,23.9173109 69.2510959,23.9173109 L60.8136986,23.9173109 L60.8136986,0.822857143 Z M65.6465753,5.38890756 L65.6465753,19.7889076 L69.2551233,19.7889076 C74.0054384,19.7889076 76.4722192,16.9653782 76.4722192,12.4376471 L76.4722192,12.3710924 C76.4722192,7.85142857 74.0054384,5.38890756 69.2551233,5.38890756 L65.6465753,5.38890756 Z" id="Shape" fill="#D02728"/>
                <path d="M142.888018,2.23058824 L142.888018,2.21848739 C142.884691,1.10463585 143.78355,0.198971934 144.895685,0.195621107 C146.00782,0.192288825 146.912088,1.09253501 146.915434,2.20638655 L146.915434,2.21848739 C146.918761,3.33233894 146.019902,4.23800286 144.907767,4.24135368 C143.795632,4.24468596 142.891364,3.34443978 142.888018,2.23058824 L142.888018,2.23058824 Z M146.683849,2.21848739 L146.683849,2.20638655 C146.681691,1.56871249 146.340026,0.980630558 145.787556,0.663665527 C145.235086,0.346700497 144.555744,0.349006904 144.005433,0.669715948 C143.455121,0.990424991 143.117444,1.58081334 143.119603,2.21848739 L143.119603,2.23058824 C143.114715,2.70563444 143.300838,3.16267938 143.636057,3.49879523 C143.971277,3.83491109 144.427407,4.02183782 144.901726,4.01747899 C145.37666,4.01695303 145.831784,3.82679496 146.166288,3.48912484 C146.500792,3.15145471 146.687066,2.6941441 146.683849,2.21848739 Z M144.148603,1.1697479 L145.052753,1.1697479 C145.467575,1.1697479 145.803863,1.38957983 145.803863,1.79294118 C145.802051,2.08714271 145.594415,2.3397205 145.306479,2.39798319 L145.874342,3.18252101 L145.471603,3.18252101 L144.964151,2.44436975 L144.476836,2.44436975 L144.476836,3.18655462 L144.15263,3.18655462 L144.148603,1.1697479 Z M145.016507,2.17815126 C145.306479,2.17815126 145.467575,2.02890756 145.467575,1.80907563 C145.467575,1.5892437 145.294397,1.45210084 145.016507,1.45210084 L144.472808,1.45210084 L144.472808,2.17815126 L145.016507,2.17815126 Z" id="Shape" fill="#D02728"/>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  mixins: [IconBase],
  props: {
    width: {
      type: [String],
      default: '147px'
    },
    height: {
      type: [String],
      default: '24px'
    }
  }
};
</script>
