const getCurrentLanguage = () => {
  if (localStorage.getItem('selectedLanguage')) {
    return JSON.parse(localStorage.getItem('selectedLanguage'));
  }
  return 'en';
};

export default {
  getCurrentLanguage
}
