<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" viewBox="0 0 99 41" version="1.1">
    <title>Sell</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="app.cardess-02" transform="translate(-640.000000, -120.000000)" fill-rule="nonzero">
            <g id="Sell" transform="translate(640.000000, 120.000000)">
                <g id="Layer_1-2-2" transform="translate(45.228426, 26.500000)">
                    <path d="M0.0804060914,4.62 L0.0804060914,4.59 C0.0668406714,3.554184 0.473380594,2.55663194 1.20817365,1.82272725 C1.9429667,1.08882257 2.94400841,0.680494761 3.9851269,0.69 C5.1343901,0.639393261 6.24424342,1.11237949 7.00035533,1.975 L5.92492386,3.12 C5.45456099,2.53690904 4.74658774,2.19388207 3.99517766,2.185 C3.38884277,2.20819138 2.81731132,2.47284649 2.409051,2.91947668 C2.00079068,3.36610686 1.79005518,3.95723914 1.8242132,4.56 L1.8242132,4.59 C1.78141174,5.20668881 1.99493193,5.81382095 2.41484248,6.26941978 C2.83475304,6.7250186 3.42423221,6.98913287 4.04543147,7 C4.79727811,6.98445048 5.50594885,6.64739119 5.99025381,6.075 L7.03050761,7.075 C5.97724097,8.33409992 4.24676563,8.80645874 2.69518494,8.2583904 C1.14360425,7.71032206 0.099896396,6.25803227 0.0804060914,4.62 L0.0804060914,4.62 Z" id="Path" fill="#231F20"/>
                    <path d="M7.39233503,6.155 L7.39233503,6.155 C7.39233503,4.54 8.65873096,3.75 10.4879695,3.75 C11.1706686,3.74162003 11.8500066,3.8463856 12.4981218,4.06 L12.4981218,3.85 C12.4981218,2.79 11.8448223,2.225 10.638731,2.225 C9.89793991,2.22753809 9.16627422,2.38779387 8.4928934,2.695 L8.04060914,1.35 C8.92265012,0.934138689 9.88892078,0.725438258 10.8648731,0.74 C11.7820695,0.663848904 12.6904631,0.965720007 13.3775635,1.575 C13.9525355,2.19902514 14.2450043,3.03043006 14.1866497,3.875 L14.1866497,8.3 L12.5081726,8.3 L12.5081726,7.365 C11.9064042,8.09196992 10.9964492,8.4955861 10.0507614,8.455 C8.64365482,8.46 7.39233503,7.655 7.39233503,6.155 Z M12.5332995,5.615 L12.5332995,5.115 C11.9881258,4.91463513 11.4108498,4.81467269 10.8296954,4.82 C9.72411168,4.82 9.07081218,5.29 9.07081218,6.065 L9.07081218,6.095 C9.07081218,6.815 9.70903553,7.225 10.533198,7.225 C11.668934,7.225 12.5433503,6.57 12.5433503,5.625 L12.5332995,5.615 Z" id="Shape" fill="#231F20"/>
                    <path d="M15.5133503,0.835 L17.2320305,0.835 L17.2320305,2.5 C17.6035883,1.36736497 18.6891894,0.620692256 19.8854315,0.675 L19.8854315,2.5 L19.7849239,2.5 C18.2773096,2.5 17.2270051,3.5 17.2320305,5.455 L17.2320305,8.295 L15.5133503,8.295 L15.5133503,0.835 Z" id="Path" fill="#231F20"/>
                    <path d="M28.3029442,4.585 L28.3029442,4.56 C28.3029442,2.425 29.8105584,0.67 31.9614213,0.67 C34.3484772,0.67 35.5595939,2.53 35.5595939,4.67 C35.5595939,4.825 35.5595939,4.98 35.5595939,5.17 L30.0316751,5.17 C30.1348404,6.27017273 31.0827293,7.0992248 32.1925888,7.06 C32.9587488,7.05839444 33.6886639,6.73522267 34.2027411,6.17 L35.2078173,7.06 C34.4600499,7.97792943 33.3243374,8.4957583 32.1373096,8.46 C31.1016378,8.48723447 30.1010377,8.08568387 29.3741894,7.35113283 C28.6473411,6.61658178 28.2594144,5.61489075 28.3029442,4.585 Z M33.8559898,4.03 C33.7404061,2.93 33.0871066,2.065 31.9513706,2.07 C30.901066,2.07 30.162335,2.875 30.0065482,4.035 L33.8559898,4.03 Z" id="Shape" fill="#D02728"/>
                    <path d="M35.9716751,7.31 L36.7405584,6.15 C37.4407511,6.70846942 38.3014365,7.03069088 39.1979695,7.07 C39.9367005,7.07 40.3588325,6.755 40.3588325,6.26 L40.3588325,6.26 C40.3588325,5.68 39.5648223,5.48 38.6853807,5.215 C37.5747716,4.9 36.3385279,4.45 36.3385279,3.025 L36.3385279,3.025 C36.3385279,1.61 37.514467,0.745 39.0070051,0.745 C39.9887995,0.759280181 40.9460383,1.05246111 41.7659391,1.59 L41.0824873,2.805 C40.4512272,2.39023022 39.7218033,2.14716767 38.966802,2.1 C38.2984264,2.1 37.9114721,2.41 37.9114721,2.835 L37.9114721,2.865 C37.9114721,3.415 38.7255838,3.64 39.6050254,3.92 C40.7005584,4.26 41.936802,4.755 41.936802,6.095 L41.936802,6.125 C41.936802,7.695 40.7156345,8.47 39.1527411,8.475 C37.99268,8.44421357 36.8749553,8.03486989 35.9716751,7.31 Z" id="Path" fill="#231F20"/>
                    <path d="M42.213198,7.305 L42.9820812,6.15 C43.6847971,6.70674842 44.5470294,7.02713943 45.4445178,7.065 C46.1782234,7.065 46.6053807,6.755 46.6053807,6.26 L46.6053807,6.23 C46.6053807,5.65 45.8063452,5.455 44.9269036,5.185 C43.8213198,4.87 42.5850761,4.42 42.5800508,2.995 L42.5800508,2.97 C42.5800508,1.555 43.7610152,0.69 45.2485279,0.69 C46.2304547,0.70344472 47.1879239,0.996696191 48.0074619,1.535 L47.3240102,2.75 C46.697574,2.3405594 45.9760568,2.09782532 45.2284264,2.045 C44.5600508,2.045 44.1781218,2.36 44.1781218,2.785 L44.1781218,2.785 C44.1781218,3.335 44.9872081,3.565 45.8716751,3.845 C46.9621827,4.185 48.1984264,4.675 48.2034518,6.02 L48.2034518,6.05 C48.2034518,7.615 46.9822843,8.395 45.4193909,8.395 C44.2590369,8.39371314 43.1317189,8.01046217 42.213198,7.305 L42.213198,7.305 Z" id="Path" fill="#231F20"/>
                    <path d="M20.7146193,0.675 L23.5790863,0.675 C25.9962944,0.675 27.6647208,2.35 27.6647208,4.545 L27.6647208,4.545 C27.6647208,6.74 26.0013198,8.44 23.5841117,8.44 L20.7246701,8.44 L20.7146193,0.675 Z M22.3579188,2.215 L22.3579188,7.07 L23.5790863,7.07 C25.1922335,7.07 26.0314721,6.115 26.0314721,4.57 L26.0314721,4.57 C26.0314721,3.07 25.1922335,2.22 23.5790863,2.22 L22.3579188,2.215 Z" id="Shape" fill="#D02728"/>
                    <path d="M48.5703046,1.13 L48.5703046,1.13 C48.5703046,0.887059372 48.7005697,0.662572956 48.9120304,0.54110263 C49.1234912,0.419632304 49.3840215,0.419632304 49.5954823,0.54110263 C49.806943,0.662572956 49.9372082,0.887059372 49.9372081,1.13 L49.9372081,1.13 C49.9385805,1.31163264 49.8663216,1.48615059 49.7367604,1.61411657 C49.6071991,1.74208254 49.4312706,1.81269606 49.248731,1.81 C48.8743744,1.8045716 48.5729976,1.50249576 48.5703046,1.13 L48.5703046,1.13 Z M49.8618274,1.13 L49.8618274,1.13 C49.8332723,0.818449886 49.5706999,0.579943152 49.256269,0.579943152 C48.9418381,0.579943152 48.6792657,0.818449886 48.6507107,1.13 L48.6507107,1.13 C48.6493723,1.28913154 48.7116272,1.44227387 48.823779,1.55573459 C48.9359307,1.6691953 49.0887916,1.73367943 49.248731,1.735 L49.248731,1.735 C49.5825952,1.73225463 49.8517881,1.46218935 49.8517766,1.13 L49.8618274,1.13 Z M49.0024873,0.78 L49.3090355,0.78 C49.4497462,0.78 49.5603046,0.855 49.5603046,0.99 C49.560247,1.0885941 49.4912597,1.17387271 49.394467,1.195 L49.5854315,1.46 L49.4497462,1.46 L49.2788832,1.21 L49.1130457,1.21 L49.1130457,1.46 L49.0024873,1.46 L49.0024873,0.78 Z M49.2939594,1.12 C49.394467,1.12 49.4497462,1.07 49.4497462,0.995 C49.4497462,0.92 49.3894416,0.875 49.2939594,0.875 L49.1080203,0.875 L49.1080203,1.12 L49.2939594,1.12 Z" id="Shape" fill="#D02728"/>
                </g>
                <path d="M45.2736548,17.32 L47.8315736,14.32 C49.2831896,15.3946845 51.0476174,15.9687383 52.8569543,15.955 C53.7162944,15.955 54.0831472,15.735 54.0831472,15.355 L54.0831472,15.315 C54.0831472,14.915 53.6308629,14.695 52.0729949,14.395 C48.8517259,13.76 46.0425381,12.86 46.0425381,9.895 L46.0425381,9.855 C46.0425381,7.195 48.1732995,5.135 52.1282741,5.135 C54.8922335,5.135 56.9425888,5.77 58.6009645,7.05 L56.284264,10.235 C55.024088,9.35242396 53.5235593,8.87279624 51.9825381,8.86 C51.2639086,8.86 50.9372589,9.1 50.9372589,9.44 L50.9372589,9.5 C50.9372589,9.88 51.3242132,10.12 52.8418782,10.395 C56.5104061,11.055 58.9678173,12.09 58.9728426,14.895 L58.9728426,14.935 C58.9728426,17.875 56.4953299,19.68 52.6458883,19.68 C49.7211168,19.68 47.097868,18.88 45.2736548,17.32 Z" id="Path" fill="#231F20"/>
                <polygon id="Path" fill="#231F20" points="60.5106091 5.41 72.7423858 5.41 72.7423858 9.25 65.2043147 9.25 65.2043147 10.67 72.2096954 10.67 72.2096954 14.05 65.2294416 14.05 65.2294416 15.55 72.8680203 15.55 72.8680203 19.4 60.5307107 19.4"/>
                <polygon id="Path" fill="#231F20" points="74.8429949 5.4 79.6372081 5.4 79.6372081 15.4 86.3762437 15.4 86.3762437 19.4 74.817868 19.4"/>
                <polygon id="Path" fill="#231F20" points="87.4416244 5.395 92.2358376 5.395 92.2358376 15.395 99 15.395 99 19.395 87.4416244 19.395"/>
                <polygon id="Path" fill="#D02727" points="17.3476142 19.995 17.357665 40 34.690203 29.985 34.6751269 9.985"/>
                <polygon id="Path" fill="#9F1D21" points="0 10.01 0.0150761421 30.01 17.357665 40 17.3476142 19.995"/>
                <polygon id="Path" fill="#ED3726" points="17.3325381 0 0 10.015 17.3476142 20.005 34.6751269 9.99"/>
                <path d="M28.1773096,16.55 L25.6947716,8.47 L8.97532995,8.5 L6.53299492,16.565 L6.53299492,16.565 L6.53299492,25.185 L6.53299492,25.185 L6.53299492,26.835 C6.53299492,27.1111424 6.75798888,27.335 7.03553299,27.335 L9.82461929,27.335 C10.1021634,27.335 10.3271574,27.1111424 10.3271574,26.835 L10.3271574,25.19 L24.3982234,25.19 L24.3982234,26.835 C24.3982234,27.1111424 24.6232173,27.335 24.9007614,27.335 L27.6898477,27.335 C27.9673918,27.335 28.1923858,27.1111424 28.1923858,26.835 L28.1923858,25.185 L28.1923858,25.185 L28.1923858,16.55 L28.1773096,16.55 Z M10.005533,9.5 L24.6645685,9.5 L26.2224365,15.535 L8.45771574,15.535 L10.005533,9.5 Z M12.0609137,22.5 L9.04568528,22.5 C8.49059705,22.5 8.04060914,22.0522847 8.04060914,21.5 L8.04060914,20.5 L11.0558376,20.5 C11.6109258,20.5 12.0609137,20.9477153 12.0609137,21.5 L12.0609137,22.5 Z M19.0964467,21 L17.3677157,22 L15.6339594,21 L15.6339594,19 L15.6339594,19 L17.3626904,18 L19.0964467,19 L19.0964467,19 L19.0964467,21 Z M22.6745178,21.5 C22.6745178,20.9477153 23.1245057,20.5 23.6795939,20.5 L26.6948223,20.5 L26.6948223,21.5 C26.6948223,22.0522847 26.2448344,22.5 25.6897462,22.5 L22.6745178,22.5 L22.6745178,21.5 Z" id="Shape" fill="#FFFFFF"/>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  mixins: [IconBase],
  props: {
    width: {
      type: [String],
      default: '99px'
    },
    height: {
      type: [String],
      default: '41px'
    }
  }
};
</script>
