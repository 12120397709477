import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en/translations.json';
import language from '@/util/language';

Vue.use(VueI18n);

export function createI18n () {
  return new VueI18n({
    // eslint-disable-next-line
    locale: language.getCurrentLanguage(),
    messages: {
      'en': en
    },
    silentTranslationWarn: true
  })
}
