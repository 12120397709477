<template>
  <div>
    <div class="content--logo"><icon-capture /></div>
    <div class="content--header">
      <div class="content--headline">{{$t('cardessCapture.headline')}}</div>
      <div class="content--manual">
        <icon-download />
        <span> {{$t('manual')}}</span>
      </div>
    </div>
    <div class="content--container">
      <div>
        <div class="content--subheadline">
          {{$t('cardessCapture.about.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessCapture.about.description')}}
        </div>
      </div>
      <div>
        <div class="content--subheadline">
          {{$t('cardessCapture.howItWorks.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessCapture.howItWorks.description')}}
        </div>
      </div>
      <div>
        <div class="content--subheadline">
          {{$t('cardessCapture.technical.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessCapture.technical.description')}}
        </div>
      </div>
      <div>
        <button class="content--button">{{$t('cardessCapture.button.sellApp')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import IconCapture from '@/components/icons/IconCapture';
import IconDownload from '@/components/icons/IconDownload';

export default {
  name: 'CardessCapture',
  components: {
    IconCapture,
    IconDownload
  }
}
</script>
