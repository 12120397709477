<template>
  <a class="content--button" :title="title" :href="itmsLink">{{ title }}</a>
</template>

<script>
export default {
  name: 'ItmsServices',
  props: [
    'title',
    'fileName'
  ],
  computed: {
    itmsLink() {
      return 'itms-services://?action=download-manifest&url=' + window.location.origin + window.location.pathname + 'applications/' + this.fileName
    }
  }
}
</script>
