<template>
  <div class="cardess-container">
    <div class="container-inner">
        <div class="navbar">
            <icon-cardess-logo />
            <div class="cardess-login__container--lang">
              <language-select
                @setCurrentLanguage="setCurrentLanguage"
                :currentLanguage="currentLanguage"
                :availableLanguages="availableLanguages" />
            </div>
        </div>
    </div>
    <div class="app-wrapper">
        <div class="app-wrapper--container">
            <div class="sidebar">
                <div class="sidebar--headline">
                  {{$t('sidebar.headline')}}
                </div>
                <div class="sidebar--application">
                  <div class="sidebar--subheadline">
                    {{$t('sidebar.subheadline.application')}}
                  </div>
                  <div class="sidebar--items">
                    <!--
                    <router-link class="sidebar--item" :to="{ name: 'CardessSell' }">
                      <span class="icon">&#9644;</span>
                      <span class="sidebar--item-label">
                        {{$t('sidebar.nav.cardessSell')}}
                      </span>
                    </router-link>
                    -->

                    <!--
                    <router-link class="sidebar--item" :to="{ name: 'CardessCapture' }">
                      <span class="icon">&#9644;</span>
                      <span class="sidebar--item-label">
                        {{$t('sidebar.nav.cardessCapture')}}
                      </span>
                    </router-link>
                    -->

                    <router-link class="sidebar--item" :to="{ name: 'CardessAppraisal' }">
                      <span class="icon">&#9644;</span>
                      <span class="sidebar--item-label">
                        {{$t('sidebar.nav.cardessAppraisal')}}
                      </span>
                    </router-link>

                    <router-link class="sidebar--item" :to="{ name: 'CardessCheck' }">
                      <span class="icon">&#9644;</span>
                      <span class="sidebar--item-label">
                        {{$t('sidebar.nav.cardessCheck')}}
                      </span>
                    </router-link>
                  </div>
                </div>

                <!--
                <div class="sidebar--support">
                  <div class="sidebar--subheadline">
                    {{$t('sidebar.subheadline.support')}}
                  </div>
                  <div class="sidebar--items">
                    <div class="sidebar--item">
                      <a href="tel:872128221" class="support-link">
                        <icon-phone /> {{$t('sidebar.support.phone')}}
                      </a>
                    </div>
                    <div class="sidebar--item">
                      <a href="mailto:appsupport@cardess.com" class="support-link">
                        <icon-mail /> {{$t('sidebar.support.email')}}
                      </a>
                    </div>
                  </div>
                </div>
                -->
            </div>
            <div class="content">
              <router-view />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import LanguageSelect from 'language-selection';
import language from '@/util/language';
import IconCardessLogo from '@/components/icons/IconCardessLogo';

export default {
  name: 'App',
  data() {
    return {
      currentLanguage: language.getCurrentLanguage() || 'en'
    };
  },
  methods: {
    setCurrentLanguage(lang) {
      this.currentLanguage = lang;
      this.i18n.locale = lang;
      localStorage.setItem('selectedLanguage', JSON.stringify(lang));
    }
  },
  computed: {
    availableLanguages() {
      return [{
        code: 'en',
        name: 'English'
      }
      ];
    }
  },
  components: {
    LanguageSelect,
    IconCardessLogo
  }
};
</script>

<style src="./style/styles.scss" lang="scss">
.navbar__container {
  height: 10%;
}
</style>
