<template>
  <div>
    <div class="content--logo"><icon-sell /></div>
    <div class="content--header">
      <div class="content--headline">{{$t('cardessSell.headline')}}</div>
      <div class="content--manual">
        <icon-download />
        <span> {{$t('manual')}}</span>
      </div>
    </div>
    <div class="content--container">
      <div>
        <div class="content--subheadline">
          {{$t('cardessSell.about.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessSell.about.description')}}
        </div>
      </div>
      <div>
        <div class="content--subheadline">
          {{$t('cardessSell.howItWorks.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessSell.howItWorks.description')}}
        </div>
      </div>
      <div>
        <div class="content--subheadline">
          {{$t('cardessSell.technical.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessSell.technical.description')}}
        </div>
      </div>
      <div>
        <button class="content--button">{{$t('cardessSell.button.sellApp')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import IconSell from '@/components/icons/IconSell';
import IconDownload from '@/components/icons/IconDownload';

export default {
  name: 'CardessSell',
  components: {
    IconSell,
    IconDownload
  }
}
</script>
