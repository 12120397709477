<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 28
    },
    height: {
      type: [Number, String],
      default: 28
    },
    iconColor: {
      type: String,
      default: '#EE3727'
    },
    viewBox: {
      type: [String, Number],
      default: '0 0 28 28'
    }
  }
}
</script>

<style>
</style>
