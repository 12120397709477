<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" viewBox="0 0 13 15" version="1.1">
    <title>Group 3</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="app.cardess-02" transform="translate(-1372.000000, -205.000000)" fill="#0573F7" fill-rule="nonzero">
            <g id="Group-3" transform="translate(1372.000000, 205.000000)">
                <path d="M9.92766667,6.381375 C9.85746667,6.22475 9.7032,6.125 9.53333333,6.125 L7.8,6.125 L7.8,0.4375 C7.8,0.196 7.60586667,0 7.36666667,0 L5.63333333,0 C5.39413333,0 5.2,0.196 5.2,0.4375 L5.2,6.125 L3.46666667,6.125 C3.2968,6.125 3.14253333,6.225625 3.07233333,6.381375 C3.00126667,6.538 3.029,6.720875 3.1408,6.850375 L6.17413333,10.350375 C6.25646667,10.44575 6.3752,10.5 6.5,10.5 C6.6248,10.5 6.74353333,10.444875 6.82586667,10.350375 L9.8592,6.850375 C9.97186667,6.72175 9.99786667,6.538 9.92766667,6.381375 Z" id="Path"/>
                <path d="M11.2666667,9.625 L11.2666667,12.25 L1.73333333,12.25 L1.73333333,9.625 L0,9.625 L0,13.125 C0,13.608875 0.388266667,14 0.866666667,14 L12.1333333,14 C12.6126,14 13,13.608875 13,13.125 L13,9.625 L11.2666667,9.625 Z" id="Path"/>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  mixins: [IconBase],
  props: {
    width: {
      type: [String],
      default: '13px'
    },
    height: {
      type: [String],
      default: '15px'
    }
  }
};
</script>
