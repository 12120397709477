<template>
  <div>
    <div class="content--logo"><icon-check /></div>
    <div class="content--header">
      <div class="content--headline">{{$t('cardessCheck.headline')}}</div>
      <!--
      <div class="content--manual">
        <icon-download />
        <span> {{$t('manual')}}</span>
      </div>
      -->
    </div>
    <div class="content--container">
      <!--
      <div>
        <div class="content--subheadline">
          {{$t('cardessCheck.about.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessCheck.about.description')}}
        </div>
      </div>
      -->
      <!--
      <div>
        <div class="content--subheadline">
          {{$t('cardessCheck.howItWorks.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessCheck.howItWorks.description')}}
        </div>
      </div>
      -->
      <!--
      <div>
        <div class="content--subheadline">
          {{$t('cardessCheck.technical.headline')}}
        </div>
        <div class="content--description">
          {{$t('cardessCheck.technical.description')}}
        </div>
      </div>
      -->
      <div>
        <itms-services-link :title="$t('cardessCheck.button.sellApp')" file-name="appcc.plist" />
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck';
import ItmsServicesLink from '@/components/ItmsServicesLink';

export default {
  name: 'CardessCheck',
  components: {
    ItmsServicesLink,
    IconCheck
  }
}
</script>
