<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" viewBox="0 0 121 41" version="1.1">
    <title>Check</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="app.cardess-02-2" transform="translate(-640.000000, -120.000000)" fill-rule="nonzero">
            <g id="Check" transform="translate(640.000000, 120.000000)">
                <g id="Layer_1-2-2" transform="translate(45.000000, 27.000000)">
                    <path d="M-5.42101086e-20,4.14 L-5.42101086e-20,4.11 C-0.0134969077,3.074184 0.390989783,2.07663194 1.12207176,1.34272725 C1.85315374,0.608822566 2.84913968,0.200494761 3.885,0.21 C5.02887466,0.162848314 6.13234426,0.637340245 6.885,1.5 L5.81,2.64 C5.34103494,2.05878132 4.63678291,1.71765924 3.89,1.71 C3.2867274,1.73319138 2.71808248,1.99784649 2.31188408,2.44447668 C1.90568568,2.89110686 1.6960145,3.48223914 1.73,4.085 L1.73,4.085 C1.6860758,4.6999732 1.89586626,5.30611304 2.31054882,5.76235832 C2.72523138,6.21860359 3.30863873,6.48516203 3.925,6.5 C4.6724031,6.48635013 5.37761636,6.15105494 5.86,5.58 L6.89,6.58 C5.83962279,7.81757564 4.13383628,8.27745795 2.60371799,7.73558733 C1.0735997,7.1937167 0.0374133207,5.7628019 7.10537315e-15,4.14 L-5.42101086e-20,4.14 Z" id="Path" fill="#231F20"/>
                    <path d="M7.255,5.68 L7.255,5.68 C7.255,4.065 8.515,3.275 10.335,3.275 C11.0139271,3.2710893 11.6891138,3.37574324 12.335,3.585 L12.335,3.4 C12.335,2.34 11.685,1.775 10.48,1.775 C9.74455166,1.77747786 9.01819879,1.93775291 8.35,2.245 L7.895,0.9 C8.77474638,0.485792535 9.73775527,0.278822597 10.71,0.295 C11.6117495,0.22584579 12.5029959,0.52533347 13.18,1.125 C13.7523316,1.7416734 14.0484615,2.56505893 14,3.405 L14,7.83 L12.335,7.83 L12.335,6.895 C11.7390272,7.62155532 10.8392409,8.02987723 9.9,8 C8.5,8 7.255,7.18 7.255,5.68 Z M12.37,5.145 L12.37,4.645 C11.8275797,4.44463513 11.2532192,4.34467269 10.675,4.35 C9.575,4.35 8.925,4.815 8.925,5.59 L8.925,5.62 C8.925,6.34 9.56,6.75 10.38,6.75 C11.51,6.75 12.38,6.1 12.38,5.155 L12.37,5.145 Z" id="Shape" fill="#231F20"/>
                    <path d="M15.34,0.365 L17.045,0.365 L17.045,2.05 C17.515,0.93 18.375,0.17 19.69,0.225 L19.69,2.035 L19.59,2.035 C18.09,2.035 17.045,3.035 17.045,4.99 L17.045,7.83 L15.34,7.83 L15.34,0.365 Z" id="Path" fill="#231F20"/>
                    <path d="M28.06,4.125 L28.06,4.125 C28.06,1.99 29.56,0.235 31.705,0.235 C34.08,0.235 35.28,2.1 35.28,4.235 C35.28,4.39 35.28,4.545 35.25,4.735 L29.75,4.735 C29.8501619,5.83716369 30.7939834,6.66904349 31.9,6.63 C32.6626605,6.62525566 33.3882933,6.30053497 33.9,5.735 L34.9,6.63 C34.1570509,7.54589232 33.0287565,8.06194826 31.85,8.025 C30.8188583,8.04711339 29.8249324,7.63956614 29.106143,6.89991485 C28.3873537,6.16026356 28.0084022,5.15508728 28.06,4.125 Z M33.56,3.575 C33.445,2.47 32.8,1.61 31.665,1.61 C30.62,1.61 29.885,2.415 29.73,3.575 L33.56,3.575 Z" id="Shape" fill="#D02728"/>
                    <path d="M35.69,6.855 L36.45,5.7 C37.1500211,6.25523712 38.00739,6.57543814 38.9,6.615 C39.635,6.615 40.055,6.305 40.055,5.81 L40.055,5.78 C40.055,5.205 39.265,5.005 38.39,4.735 C37.285,4.42 36.055,3.97 36.055,2.545 L36.055,2.5 C36.055,1.085 37.23,0.22 38.715,0.22 C39.6918076,0.239058839 40.6433553,0.533709295 41.46,1.07 L40.78,2.285 C40.1507738,1.86805885 39.4232835,1.62326353 38.67,1.575 C38.005,1.575 37.625,1.885 37.625,2.31 L37.625,2.34 C37.625,2.89 38.43,3.115 39.305,3.4 C40.395,3.74 41.625,4.235 41.625,5.575 L41.625,5.605 C41.625,7.175 40.41,7.95 38.855,7.95 C37.7084954,7.94178673 36.5964559,7.5570527 35.69,6.855 L35.69,6.855 Z" id="Path" fill="#231F20"/>
                    <path d="M41.92,6.855 L42.685,5.7 C43.3828021,6.2557603 44.2387741,6.5760934 45.13,6.615 C45.865,6.615 46.285,6.305 46.285,5.81 L46.285,5.78 C46.285,5.205 45.495,5.005 44.62,4.735 C43.52,4.42 42.29,3.97 42.29,2.545 L42.29,2.5 C42.29,1.085 43.46,0.22 44.945,0.22 C45.9205831,0.236992357 46.8710877,0.531856923 47.685,1.07 L47,2.305 C46.371928,1.89023022 45.6461881,1.64716767 44.895,1.6 C44.23,1.6 43.845,1.91 43.845,2.335 L43.845,2.335 C43.845,2.89 44.65,3.115 45.53,3.395 C46.615,3.735 47.845,4.23 47.845,5.575 L47.845,5.575 C47.845,7.145 46.63,7.92 45.075,7.92 C43.9353895,7.91821688 42.8275481,7.54425456 41.92,6.855 L41.92,6.855 Z" id="Path" fill="#231F20"/>
                    <path d="M20.5,0.21 L23.35,0.21 C25.75,0.21 27.41,1.89 27.41,4.085 L27.41,4.085 C27.41,6.275 25.75,7.98 23.35,7.98 L20.5,7.98 L20.5,0.21 Z M22.13,1.75 L22.13,6.605 L23.35,6.605 C24.955,6.605 25.79,5.655 25.79,4.105 L25.79,4.105 C25.79,2.58 24.955,1.75 23.35,1.75 L22.13,1.75 Z" id="Shape" fill="#D02728"/>
                    <path d="M48.23,0.685 L48.23,0.685 C48.23,0.30944637 48.5344464,0.005 48.91,0.005 C49.2855536,0.005 49.59,0.30944637 49.59,0.685 L49.59,0.685 C49.59,1.06055363 49.2855536,1.365 48.91,1.365 C48.5344464,1.365 48.23,1.06055363 48.23,0.685 L48.23,0.685 Z M49.51,0.685 L49.51,0.685 C49.51,0.35362915 49.2413708,0.085 48.91,0.085 C48.5786292,0.085 48.31,0.35362915 48.31,0.685 L48.31,0.685 C48.3072089,1.01444868 48.5705851,1.28452081 48.9,1.29 L48.9,1.29 C49.06,1.29 49.213375,1.22610556 49.3260417,1.1125 C49.4387083,0.998894445 49.5013333,0.844994444 49.5,0.685 L49.51,0.685 Z M48.655,0.335 L48.96,0.335 C49.1,0.335 49.215,0.41 49.215,0.545 C49.2144489,0.645121984 49.1432907,0.730930374 49.045,0.75 L49.24,1.01 L49.1,1.01 L48.93,0.765 L48.765,0.765 L48.765,1 L48.655,1 L48.655,0.335 Z M48.95,0.675 C49.045,0.675 49.1,0.625 49.1,0.55 C49.1,0.475 49.045,0.43 48.95,0.43 L48.765,0.43 L48.765,0.675 L48.95,0.675 Z" id="Shape" fill="#D02728"/>
                </g>
                <path d="M45.835,12.54 L45.835,12.5 C45.835,8.32 49.16,5.205 53.42,5.205 C56.56,5.205 58.68,6.705 59.88,8.84 L55.945,11.1 C55.415,10.1 54.665,9.4 53.36,9.4 C51.745,9.4 50.685,10.78 50.685,12.46 L50.685,12.5 C50.685,14.32 51.79,15.6 53.36,15.6 C54.705,15.6 55.455,14.88 56.05,13.84 L60,16 C58.795,18.08 56.8,19.7801021 53.25,19.7801021 C49.28,19.8 45.835,16.88 45.835,12.54 Z" id="Path" fill="#231F20"/>
                <polygon id="Path" fill="#231F20" points="61.22 5.5 66 5.5 66 10.435 70.365 10.435 70.365 5.5 75.135 5.5 75.135 19.5 70.365 19.5 70.365 14.5 66 14.5 66 19.5 61.23 19.5"/>
                <polygon id="Path" fill="#231F20" points="77.37 5.5 89.54 5.5 89.54 9.335 82.04 9.335 82.04 10.755 89.015 10.755 89.015 14.135 82.06 14.135 82.06 15.635 89.645 15.635 89.645 19.5 77.37 19.5"/>
                <path d="M90.89,12.54 L90.89,12.5 C90.89,8.32 94.215,5.205 98.475,5.205 C101.615,5.205 103.735,6.705 104.94,8.84 L101,11.1 C100.47,10.1 99.715,9.4 98.41,9.4 C96.8,9.4 95.74,10.78 95.74,12.46 L95.74,12.5 C95.74,14.32 96.84,15.6 98.41,15.6 C99.755,15.6 100.51,14.88 101.1,13.84 L105.04,16 C103.835,18.08 101.84,19.7801021 98.29,19.7801021 C94.335,19.8 90.89,16.88 90.89,12.54 Z" id="Path" fill="#231F20"/>
                <polygon id="Path" fill="#231F20" points="106.28 5.5 111.045 5.5 111.045 10.74 115.105 5.5 120.65 5.5 115.65 11.575 120.75 19.5 115.065 19.5 112.27 15.06 111.045 16.5 111.045 19.5 106.28 19.5"/>
                <polygon id="Path" fill="#D02727" points="17.25 19.995 17.25 40 34.5 30 34.5 9.995"/>
                <polygon id="Path" fill="#9F1D21" points="0 9.995 0 30 17.25 40 17.25 19.995"/>
                <polygon id="Path" fill="#ED3726" points="17.25 0 0 10 17.25 20.005 34.5 10"/>
                <path d="M26.25,15 L24.195,8.25 L10.305,8.25 L8.25,15 L8.25,15 L8.25,22.21 L8.25,22.21 L8.25,23.585 C8.25,23.8150209 8.43499538,24.0022617 8.665,24.005 L11,24.005 C11.2300046,24.0022617 11.4150163,23.8150209 11.415,23.585 L11.415,22.215 L23.1,22.215 L23.1,23.59 C23.1050037,23.8104918 23.2796989,23.9895545 23.5,24 L25.82,24 C26.0500046,23.9972617 26.2350163,23.8100209 26.235,23.58 L26.235,22.215 L26.235,22.215 L26.235,15 L26.25,15 Z M11.16,9.125 L23.34,9.125 L24.625,14.16 L9.875,14.16 L11.16,9.125 Z M12.84,20 L10.34,20 C10.1132777,20.0014594 9.89570916,19.9106599 9.73728604,19.7484648 C9.57886292,19.5862697 9.49320707,19.3666252 9.5,19.14 L9.5,18.305 L12,18.305 C12.2214558,18.305 12.4338413,18.392973 12.5904342,18.5495658 C12.747027,18.7061587 12.835,18.9185442 12.835,19.14 L12.84,20 Z M18.69,18.745 L17.25,19.555 L15.81,18.72 L15.81,17.055 L15.81,17.055 L17.245,16.22 L18.68,17.055 L18.68,17.055 L18.69,18.745 Z M21.66,19.165 C21.6532071,18.9383748 21.7388629,18.7187303 21.897286,18.5565352 C22.0557092,18.3943401 22.2732777,18.3035406 22.5,18.305 L25,18.305 L25,19.14 C25.0067622,19.3657549 24.9217836,19.5846202 24.7644518,19.7466625 C24.60712,19.9087048 24.3908561,20.0001013 24.165,20.0000001 L21.665,20.0000001 L21.66,19.165 Z" id="Shape" fill="#FFFFFF"/>
                <path d="M27.25,27.5 L27.25,24.5 L7.25,24.5 L7.25,27.5 L12.5,27.5 L15.73,29.365 L11.66,31.715 L11.66,31.715 L14.66,31.715 L17.245,30.215 L19.83,31.715 L22.83,31.715 L22.83,31.715 L18.76,29.365 L22,27.5 L27.25,27.5 Z M17.25,28.5 L15.5,27.5 L19,27.5 L17.25,28.5 Z" id="Shape" fill="#FFFFFF"/>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  mixins: [IconBase],
  props: {
    width: {
      type: [String],
      default: '121px'
    },
    height: {
      type: [String],
      default: '41px'
    }
  }
};
</script>
